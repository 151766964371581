<div class="d-flex justify-content-center align-items-center mt-5">
  <div class="card p-3">
    <main class="form-signin w-100 m-auto">
      <form [formGroup]="form" (ngSubmit)="login()">
        <img class="mb-4" src="../../../assets/img/logo.png" alt="Logo Ultracondomínio" height="60">
        
        <div class="form-floating">
          <input type="email" id="email" name="email" class="form-control" formControlName="email" placeholder="name@example.com">
          <label for="email">E-mail</label>
        </div>
        <div class="form-floating mt-2">
          <input type="password" id="password" name="password" class="form-control" formControlName="password" placeholder="Password">
          <label for="password">Senha</label>
        </div>
    
        <button class="btn btn-primary w-100 py-2 my-3" type="submit" [disabled]="!form.valid">Entrar</button>
      </form>
    </main>

  </div>
</div>