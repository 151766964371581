import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { usuarioSelector } from '../../store';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  store = inject(Store);
  usuario$ = this.store.select(usuarioSelector);

  private token: string = "";

  constructor(private router: Router) {
    this.usuario$.subscribe(u => {
      this.token = u.token;
    });
  }

  public isAuthenicated(): boolean {
    if (this.token && this.token != "") {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
  
}
