import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login';
import { authCanActivateGuard } from './services/auth';

export const routes: Routes = [
  { path: "", redirectTo: 'login', pathMatch: "prefix"},
  { path: 'login', component: LoginComponent },
  { path: 'home', loadComponent: () => import("./pages/home/home.component").then(m => m.HomeComponent), canActivate: [authCanActivateGuard]},
  { path: '**', redirectTo: "login"}
];
