import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginHttpService {

  constructor() { }

  httpClient = inject(HttpClient);

  public login(email: String, senha: String): Observable<any> {
    return this.httpClient.post(`${environment.api_url}logar`, {email, senha}).pipe(
			map(response => response),
			catchError(error =>  error)
		);
  }
}
