import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { usuarioSelector } from '../../store/selectors';
import { catchError, throwError } from 'rxjs';

export const ultracondominioApiInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  let usuario$ = store.select(usuarioSelector);

  let reqClone = req;
  usuario$.subscribe(u => {
    reqClone = req.clone({
      setHeaders: {
        Authorization: `Bearer ${u.token}`
      }
    })
  });
  return next(reqClone).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors         
          console.error('Unauthorized request:', err);
          // You might trigger a re-authentication flow or redirect the user here
        } else {
          // Handle other HTTP error codes
          console.error('HTTP error:', err);
        }
      } else {
        // Handle non-HTTP errors
        console.error('An error occurred:', err);
      }

      // Re-throw the error to propagate it further
      return throwError(() => err); 
    })
  );
};
